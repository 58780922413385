import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import DashboardMusic from '../components/DashboardMusic/dashboardMusic';
import Layout from '../components/layout';
import SEO from '../components/SEO/seo';

const DashboardPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  return (
    <Layout >
        <SEO lang="ru" description="Список лучших и знаковых альбомов по версии Артёма Алексеева" title={'Albums'} keywords={['designer', 'artyom', 'alekseev', 'Artyom Alekseev', 'ux', 'ui', 'albums', 'radiohead', 'vinil']}/>
      <DashboardMusic/>
    </Layout>

  ); };

export default DashboardPage;
