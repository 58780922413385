import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { PatternLines, Pattern } from '@visx/pattern';

import axios from 'axios';

const verticalMargin = 120;

// accessors
const getArtist = (d) => d.artist.name;
const getTrackFrequency = (d) => d.playcount;

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

export type BarsProps = {
  width: number;
  height: number;
  events?: boolean;
};

let tooltipTimeout: number;

export default function DashboardMusicArtists({ width, height, events = false }: BarsProps) {
  // bounds
  const xMax = width;
  const yMax = height - verticalMargin;

  const [topTracks, setTopTracks] = useState()

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  useEffect(() => {
    (async () => {
      const res = await axios.get("https://ws.audioscrobbler.com/2.0/?format=json&method=user.gettoptracks&user=REX_005&api_key=1291b9c6b83e179e42897209011f8cc6")
      setTopTracks(res.data.toptracks.track)
    })()
  }, [])

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: topTracks && topTracks.map(getArtist),
        padding: 0.4,
      }),
    [xMax, topTracks],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: topTracks && [0, Math.max(...topTracks.map(getTrackFrequency))],
      }),
    [yMax, topTracks],
  );

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <PatternLines
            id="lines"
            height={3}
            width={3}
            stroke="#ced4da"
            strokeWidth={1}
            // fill="rgba(0,0,0,0.3)"
            orientation={['diagonal']}
      />
      <Pattern id="lines2" width={10} height={10}>
        <animateTransform
          attributeType="xml"
          attributeName="patternTransform"
          type="translate"
          from="0 0"
          to="0 30"
          dur="10s"
          repeatCount="indefinite"
        />
      <circle cx={5} cy={5} r="3" stroke="none" fill="black" transform-origin="center" />
    </Pattern>
      <rect width={width} height={height} fill="rgb(245, 242, 227)" rx={14} />
      <Group top={verticalMargin / 2}>
        {topTracks && topTracks.map((d, ind) => {
          const artist = getArtist(d);
          const barWidth = xScale.bandwidth();
          const barHeight = yMax - (yScale(getTrackFrequency(d)) ?? 0);
          const barX = xScale(artist);
          const barY = yMax - barHeight;

          return (
            <Bar
              key={`bar-${artist}-${ind}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill="url(#lines)"
              onClick={() => console.log(`clicked: ${d.artist.name}`)}
              onMouseLeave={() => {
                tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 300);
              }}
              onMouseMove={event => {
                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                // TooltipInPortal expects coordinates to be relative to containerRef
                // localPoint returns coordinates relative to the nearest SVG, which
                // is what containerRef is set to in this example.
                const eventSvgCoords = localPoint(event);
                const left = barX + barWidth / 2;
                showTooltip({
                  tooltipData: d,
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: left,
                });
              }}
            />
          );
        })}
      </Group>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div style={{ color: "#ffffff" }}>
            <strong>{tooltipData.artist.name}</strong>
          </div>
          <div>
            <small>{tooltipData.playcount}</small>
          </div>
        </TooltipInPortal>
      )}
    </svg>
  );
}
