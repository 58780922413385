import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;    
`

const DashboardSection = ({children}) => {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

export default DashboardSection
