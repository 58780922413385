import React from 'react'
import HeroSection from '../Blocks/HeroSection/heroSection'
import DashboardSection from '../DashboardSection/dashboardSection'
import DashboardMusicArtists from './DashboardMusicArtists/dashboardMusicArtists'
import styled from 'styled-components'
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import H2 from '../Blocks/H2/h2'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px;
`

const DashboardMusic = ({height, width}) => {
    return (
        <Wrapper>
        <H2>Музыка</H2>
        <ParentSize >
      {({ width, height }) =>
       <DashboardSection>
            <DashboardMusicArtists width={width/2} height={300}/>
            <DashboardMusicArtists width={width/2} height={300}/>
            <DashboardMusicArtists width={width/2} height={300}/>
        </DashboardSection>}
      </ParentSize>
      
        </Wrapper>
    )
}

export default DashboardMusic
