import React, { CSSProperties } from 'react';
import './h2.css';

interface H2Props {
  children: string;
  className?: string;
  style?: CSSProperties;
}

const H2 = ({ children, className, style }: H2Props) => {
  return (
        <h2 style={style} className={className ? `H2 ${className}` : 'H2'}>
            {children}
        </h2>
  );
};

export default H2;
